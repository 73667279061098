import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <section className="section section--body section--first"><div className="section-content"><div className="section-inner sectionLayout--insetColumn">Last week, Dashbot hosted a panel event on conversational design in San Francisco at SamsungNEXT with experts from major platforms and brands. Here are major highlights and takeaways from the discussion you’ll find helpful when designing chatbots and voice applications.
          <p>{`Speakers:`}</p>
          <p>{`Lisa Falkson — Senior VUI Designer at `}<a parentName="p" {...{
              "href": "https://www.amazon.com/"
            }}>{`Amazon`}</a></p>
          <p>{`Elle Chun — UX Conversational Designer at `}<a parentName="p" {...{
              "href": "https://www.sutherlandlabs.com/"
            }}>{`Sutherland Labs`}</a></p>
          <p>{`Joan Bajorek, Ph.D. — Linguist `}{`&`}{` Founder of `}<a parentName="p" {...{
              "href": "https://twitter.com/WomenInVoice"
            }}>{`Women in Voice`}</a></p>
          <p>{`Scott Ganz — Principal Content Designer at `}<a parentName="p" {...{
              "href": "https://www.intuit.com/"
            }}>{`Intuit`}</a></p>
          <p>{`Justina Nguyen — Developer Evangelist at `}<a parentName="p" {...{
              "href": "https://www.dashbot.io/"
            }}>{`Dashbot`}</a>{` (moderator)`}</p>
          <p><img parentName="p" {...{
              "src": "https://cdn-images-1.medium.com/max/1600/1*RlDynSPY-podzjGlrPTG2Q.jpeg",
              "alt": null
            }}></img></p>
          <figure className="graf graf--figure graf-after--p" id="3144">
  <figcaption className="imageCaption">
    Left to Right: Justina Nguyen, Elle Chun, Joan Bajorek, Scott Ganz, Lisa
    Falkson
  </figcaption>
          </figure>
### Getting Started: Happy Paths &amp; Safety Nets
          <p>{`Before diving into the technical specs of a chatbot or a voice application, take a look at the customers and define the value proposition of conversational technology for your brand. Would it take less time to do in speech than it would when tapping on a mobile phone or clicking on a website? If you’ve determined yes, the next thing to do is to study and understand your customer base.`}</p>
          <p>{`Create a profile of who they are by deeply understanding what they want, their attitude towards the subject matter, and what their potential fears are when interacting with conversational AI. When designing these interactions, Scott Ganz thinks of customers as characters and engages as much as empathy as he can to get into a person’s mindset of when they’re using the chatbot. After understanding who the users are, you can start to craft the actual conversation.`}</p>
          <p>{`Start imagining what a successful conversation looks like in what’s called the happy path. Once you have a sense of the core use cases and potential successful scenarios, you start preparing for failure by creating safety nets to catch users when they fall out of that happy path. Many companies prioritize writing happy paths, but Lisa Falkson says, “A conversational AI’s ability to address errors is equally important and you should allow for at least 50% of development time for errors.”`}</p>
          <h3>{`Building a Team: Skill Sets Needed for Conversational Products`}</h3>
          <p>{`So you’ve decided to extend your brand onto conversational interfaces and your next step is to find the right people with the right skills to help you build this product. For designing these conversations, the common denominators for roles were linguists, screenwriters, and content creators. Ganz, an Emmy-award winning screenwriter, emphasizes the importance of empathy when creating conversations and the ability to switch between the role of the customer and the brand. Joan Bajorek, a linguist Ph.D., notes the power of a clear, distinct wake word (the word/phrase used to launch your Alexa Skill or Action on Google) which needs to be thoughtfully crafted with the help of a linguist.`}</p>
          <p>{`From a development standpoint, it depends on how much you want to pull off the shelf or build in-house. Engineers with a background in human-computer interaction can be especially beneficial during the development process. More importantly, someone who can think can think of the conversation in terms of a system and knows how to integrate design, NLP, and development.`}</p>
          <h3>{`Defining Success: Conversational Metrics that Matter`}</h3>
          <p>{`Success metrics vary depending on the use case you’ve identified for your conversational AI. If you’re building a brand bot or a promotional bot that is meant to be sticky and engaging, then engagement or retention might be your KPI. However, if you’re building a customer service chatbot you may want to consider throughput and non-repeated transactions as your success metrics.`}</p>
          <p>{`Elle Chun mentions some primary KPIs her team at Sutherland Labs cares about including agent transfers, completion rates, and deflection rates. The team at Intuit drills into the percentage of interactions that the chatbot missed and how often it defaults to its fallback response. Ganz notes, “False positives are a problem, but they’re not as much a problem as false negatives.”`}</p>
          <p>{`Quantitative metrics of success are important, but you also want to keep the qualitative data in mind given how much richer and more actionable conversational data is compared to web clicks and mobile taps. Bajorek says, “I’ve definitely seen some beautiful numbers that say users got through the whole chatbot flow, but when asked how users felt about the experience they said, ‘That’s creepy. I’m not doing that again.’” Falkson and her team at Amazon take the time to look through CSAT scores and diligently read customer complaints in order to address them and tie them back to their primary KPI, retention.`}</p>
          <h3>{`Optimizing the Experience: Baking in User Feedback`}</h3>
          <p>{`The process and turnaround time of incorporating user feedback varies depending on the size of the team and current work style, but the resounding point all speakers emphasized is the importance of user testing. Smaller teams, ones where conversational designers have direct access to developers, move a little more quickly. With larger companies, the bot is a part of a larger hole so Intuit looks at both customer data in aggregate and messages that the bot could not handle properly.`}</p>
          <p>{`Usability testing is incredibly important to all of the panelists and they all conduct testing in various ways at different scales. Intuit conducts thorough customer interviews where they pay users to use their chatbot in-person and ask users for real-time feedback. Sutherland Labs also values watching customers chat with their bots in-person so they can gauge their reactions.`}</p>
          <h3>{`Big Brands: Creating and Maintaining Brand `}{`&`}{` Tone`}</h3>
          <p>{`Customers already have expectations of big brands when it comes to personality, voice, and persona so it requires a lot of thought and energy to extract the core of the brand and put it into a chatbot or a voice application. When creating the bot’s personality, think about how it feels about the brand’s subject matter, would it be formal in conversation, and how does it feel about the customers, etc. Ganz says you can use questionnaires to gather more information about a brand’s personality and use celebrities and characters as shorthands for descriptions.`}</p>
          <p>{`While you build out all of the happy paths and hire a team of content designers, Falkson notes the importance of maintaining consistency within your organization because it is expressed through the consistency of your bot’s brand and tone. As the conversational AI team grows and changes, you’ll need to be vigilant about how the bot is falling into different designer’s hands. You don’t want the bot’s tone to change when there is a change in designers. An active way to maintain brand consistency is to have a structured personality guide (similar to a website’s style guide) that shows examples of how the bot would respond in different situations and how it would talk to customers.`}</p>
          <h3>{`Tools for Conversational Design`}</h3>
          <p>{`Below is a list of design tools and resources that the panelists referenced. There was an important distinction between some of these tools being used for prototyping, understanding the different high-level paths, and viewing the script line-by-line.`}</p>
          <p><a parentName="p" {...{
              "href": "https://www.sketchapp.com/"
            }}>{`Sketch`}</a></p>
          <p><a parentName="p" {...{
              "href": "https://www.figma.com/"
            }}>{`Figma`}</a>{` — Sutherland Labs has used to create many POCs.`}</p>
          <p><a parentName="p" {...{
              "href": "https://www.twilio.com/"
            }}>{`Twilio`}</a></p>
          <p><a parentName="p" {...{
              "href": "https://www.meya.ai/"
            }}>{`Meya.ai `}</a>{`— Sutherland Labs uses to demo prototypes to clients.`}</p>
          <p>{`Google Sheets — All panelists mentioned how valuable this is for viewing the script in detail.`}</p>
          <p>{`Google Draw`}</p>
          <p><a parentName="p" {...{
              "href": "https://botmock.com/"
            }}>{`Botmock`}</a></p>
          <p><a parentName="p" {...{
              "href": "https://www.pullstring.com/product/converse"
            }}>{`PullString Converse`}</a></p>
          <p><a parentName="p" {...{
              "href": "https://getstoryline.com/"
            }}>{`Storyline`}</a></p>
          <p><a parentName="p" {...{
              "href": "https://www.jovo.tech/"
            }}>{`Jovo`}</a></p>
        </div></div></section><section className="section section--body"><div className="section-divider">- - - - - -
      </div><div className="section-content"><div className="section-inner sectionLayout--insetColumn">Watch the panel discussion Facebook live stream [here](https://www.facebook.com/dashbot.io/videos/355979025172599/).
          <p>{`Learn more about Dashbot events `}<a parentName="p" {...{
              "href": "https://www.meetup.com/Dashbot-ChatBot-and-Voice-Meetup/"
            }}>{`here`}</a>{` and join the Twitter conversation `}<a parentName="p" {...{
              "href": "https://twitter.com/dashbotio"
            }}>{`here`}</a>{`.`}</p>
        </div></div></section><section className="section section--body section--last"><div className="section-divider">- - - - - -
      </div><div className="section-content"><div className="section-inner sectionLayout--insetColumn">### About Dashbot
          <p><em parentName="p"><a parentName="em" {...{
                "href": "https://www.dashbot.io/"
              }}>{`Dashbot`}</a>{` is the leading conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable insights. In addition to traditional analytics like engagement and retention, Dashbot provides bot and voice specific analytics and tools including detailed session transcripts, NLP optimization, and conversation paths. We also have interactive tools to take action on the data, like our live-person takeover, broadcast messaging, and audience builder.`}</em></p>
        </div>[Contact us for a demo](https://www.dashbot.io/contact/sales?ref=)
      </div></section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      